import { ProtectedRouting } from "@/components/common/ProtectedRouting"
import { UIProvider } from "@/components/ui/Provider"
import { PageProps } from "@/utils/next"

export function AppProvider({ Component, pageProps }: PageProps) {
    return (
        <UIProvider isRoot>
            <ProtectedRouting appUsesNewTheme {...pageProps}>
                <Component {...pageProps} />
            </ProtectedRouting>
        </UIProvider>
    )
}
