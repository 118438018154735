const ExclamationCircle = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        width={props.width ? props.width : 14}
        height={props.height ? props.height : 14}
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
            d="M7.5 10.3267C7.5 10.0505 7.27614 9.82667 7 9.82667C6.72386 9.82667 6.5 10.0505 6.5 10.3267H7.5ZM6.5 10.3333C6.5 10.6095 6.72386 10.8333 7 10.8333C7.27614 10.8333 7.5 10.6095 7.5 10.3333H6.5ZM7.5 3.66667C7.5 3.39052 7.27614 3.16667 7 3.16667C6.72386 3.16667 6.5 3.39052 6.5 3.66667H7.5ZM6.5 8.33333C6.5 8.60948 6.72386 8.83333 7 8.83333C7.27614 8.83333 7.5 8.60948 7.5 8.33333H6.5ZM6.5 10.3267V10.3333H7.5V10.3267H6.5ZM6.5 3.66667V8.33333H7.5V3.66667H6.5ZM12.5 7C12.5 10.0376 10.0376 12.5 7 12.5V13.5C10.5899 13.5 13.5 10.5899 13.5 7H12.5ZM7 12.5C3.96243 12.5 1.5 10.0376 1.5 7H0.5C0.5 10.5899 3.41015 13.5 7 13.5V12.5ZM1.5 7C1.5 3.96243 3.96243 1.5 7 1.5V0.5C3.41015 0.5 0.5 3.41015 0.5 7H1.5ZM7 1.5C10.0376 1.5 12.5 3.96243 12.5 7H13.5C13.5 3.41015 10.5899 0.5 7 0.5V1.5Z"
            fill={props.fill ? props.fill : "#ED8585"}
        />
    </svg>
)
export default ExclamationCircle
