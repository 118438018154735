const TickIcon = (props: React.SVGProps<SVGSVGElement>) => (
    <svg
        id="icn_tick"
        width={props.width ? props.width : 24}
        height={props.height ? props.height : 24}
        viewBox="0 0 24 24"
        {...props}>
        <rect
            id="Rectangle_1407"
            data-name="Rectangle 1407"
            width={props.width ? props.width : 24}
            height={props.height ? props.height : 24}
            fill="none"
            opacity="0"
        />
        <path
            id="Union_9"
            data-name="Union 9"
            d="M-11478.342-1209.1a1,1,0,0,1-.671-.256l-.037-.035-4.243-4.245a1,1,0,0,1,0-1.412,1,1,0,0,1,1.415,0l3.535,3.535,7.777-7.777a1,1,0,0,1,1.415,0,1,1,0,0,1,0,1.415l-8.484,8.485a1,1,0,0,1-.707.292Z"
            transform="translate(11488.586 1226.587)"
            fill={props.fill ? props.fill : "#fff"}
        />
    </svg>
)
export default TickIcon
