/* eslint-disable react/prop-types */
import { UseFormRegisterReturn } from "react-hook-form"
import { ExclamationCircle, TickIcon } from "@/assets/icons"
import { theme } from "@/styles/theme"
import styled from "styled-components"
export * from "./Form/FormInput"

const Patterns = {
    violet: {
        background: theme.palette.violet300,
        borderColor: theme.palette.violet500,
    },
    white: {
        background: theme.palette.paper,
        borderColor: "#DFE2F0",
    },
}

interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {
    valid?: { error?: boolean; success?: boolean; disabled?: boolean }
    colorPattern?: keyof typeof Patterns
    inputRef?: UseFormRegisterReturn
}

const IconWrapper = styled.div`
    position: absolute;
    right: 20px;
    top: 2px;
    bottom: 2px;
    display: flex;
    align-items: center;
    pointer-events: none;
`

const InputWrapper = styled.div`
    width: 100%;
    position: relative;
`

export const InputStyled = styled.input<{
    valid?: { error?: boolean; success?: boolean }
    colorPattern?: keyof typeof Patterns
}>`
    height: 48px;
    width: 100%;
    border-radius: 8px;
    font-family: ${p => p.theme.fontFamily.rubik};
    font-size: ${p => p.theme.fontSizes.sm};
    color: ${p => p.theme.palette.black500};
    background-color: ${({ valid, colorPattern }) =>
        valid.error ? theme.palette.red100 : Patterns[colorPattern].background};
    border: 1px solid
        ${({ valid, colorPattern }) => (valid.error ? theme.palette.red400 : Patterns[colorPattern].borderColor)};
    padding: ${({ valid }) => (valid.error || valid.success ? "15px 40px 15px 13px" : "15px 13px 15px 13px")};

    input:autofill {
        border: 3px solid blue;
    }
    &:focus {
        border-color: ${({ valid }) => (valid.error ? theme.palette.red400 : theme.palette.violet900)};
        outline: 0;
        box-shadow: 0px 4px 4px rgba(169, 184, 240, 0.2);
    }
    &::placeholder {
        color: #b8bed9;
    }
    &:disabled {
        cursor: not-allowed;
        color: ${p => p.theme.palette.violet800};
    }
`

export const ValidInput: React.FC<InputProps> = ({ valid, colorPattern, inputRef, ...props }) => {
    return (
        <InputWrapper>
            <IconWrapper>
                {valid.success ? (
                    <TickIcon
                        width={22}
                        height={22}
                        fill={valid.disabled ? theme.palette.violet800 : theme.palette.green600}
                    />
                ) : null}
                {valid.error && <ExclamationCircle width={16} height={16} />}
            </IconWrapper>
            <InputStyled disabled={valid.disabled} colorPattern={colorPattern} valid={valid} {...inputRef} {...props} />
        </InputWrapper>
    )
}
ValidInput.defaultProps = {
    colorPattern: "white",
    valid: {
        error: false,
        success: false,
        disabled: false,
    },
}
