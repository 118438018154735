import { CSSProperties, useState } from "react"
import { UseFormRegisterReturn } from "react-hook-form"
import { ValidInput } from "@/components/common"
import styled from "styled-components"

type FormInputProps = {
    autoFocus?: boolean
    labelText?: string
    inputName: string
    placeholder?: string
    errors: { [x: string]: any }
    dirtyFields: { [x: string]: any }
    isDisabled?: boolean
    type?: React.HTMLInputTypeAttribute
    inputRef?: UseFormRegisterReturn
    colorPattern?: "white" | "violet"
    style?: CSSProperties
    showSuccessValidation?: boolean
}

const ItemWrapper = styled.div`
    margin-bottom: 30px;
    width: 100%;
`

const ErrorMessage = styled.div`
    position: absolute;
    margin-top: 5px;
    color: ${p => p.theme.palette.red500};
    font-size: ${p => p.theme.fontSizes.xs};
    font-family: ${p => p.theme.fontFamily.rubik};
`

const Label = styled.label`
    text-align: left;
    display: block;
    line-height: 100%;
    margin-bottom: 8px;
    font-weight: 500;
    font-family: ${p => p.theme.fontFamily.rubik};
    font-size: ${p => p.theme.fontSizes.sm};
`

export const FormInput: React.FC<FormInputProps> = ({
    autoFocus,
    inputName,
    placeholder,
    labelText,
    errors,
    isDisabled,
    dirtyFields,
    inputRef,
    type,
    colorPattern,
    style,
    showSuccessValidation = true,
}: FormInputProps) => {
    const [showErrors, setShowErrors] = useState(true)
    return (
        <ItemWrapper style={style}>
            {labelText && <Label htmlFor={inputName}>{labelText}</Label>}
            <ValidInput
                onFocus={() => setShowErrors(false)}
                onBlur={() => setShowErrors(true)}
                id={inputName}
                name={inputName}
                autoFocus={autoFocus}
                type={type}
                colorPattern={colorPattern}
                placeholder={placeholder}
                valid={{
                    error: showErrors ? errors[inputName] : false,
                    success: showSuccessValidation ? dirtyFields[inputName] && !errors[inputName] : false,
                    disabled: isDisabled,
                }}
                inputRef={inputRef}
            />
            <ErrorMessage>{showErrors && errors[inputName]?.message}</ErrorMessage>
        </ItemWrapper>
    )
}

FormInput.defaultProps = {
    type: "text",
    placeholder: "",
    isDisabled: false,
}
