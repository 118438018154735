import React from "react"
import { Trans, useTranslation } from "react-i18next"
import { Icon } from "@/components/ui/Icon"
import {
    breakpointMd,
    breakpointSm,
    paletteNeutral100,
    paletteNeutral200,
    paletteNeutral500,
    paletteSecondary500,
    paletteWhite,
    spacing2,
    spacing4,
    spacing5,
    spacing10,
    styled,
    typographyH4,
} from "@/components/ui/theme"

export type OnboardingLayoutProps = {
    children?: React.ReactNode
    className?: string
}

export const OnboardingLayout = ({ children, className }: OnboardingLayoutProps) => {
    const { t } = useTranslation("onboardingLayout")

    return (
        <Wrapper>
            <Card className={className}>
                <FormSection>
                    <AppLogoIcon />
                    {children}
                </FormSection>
                <MarketingSection>
                    <MarketingHeader>
                        <Trans t={t} i18nKey="marketingHeader" components={{ em: <Emphasis /> }} />
                    </MarketingHeader>
                    <MarketingFeature>
                        <FeatureIcon />
                        {t("marketingFeature1")}
                    </MarketingFeature>
                    <MarketingFeature>
                        <FeatureIcon />
                        {t("marketingFeature2")}
                    </MarketingFeature>
                    <MarketingFeature>
                        <FeatureIcon />
                        {t("marketingFeature3")}
                    </MarketingFeature>
                </MarketingSection>
            </Card>
        </Wrapper>
    )
}

const Wrapper = styled.div`
    background-color: ${paletteNeutral100};
    height: calc(100vh);
    padding: ${spacing4};

    @media (min-width: ${breakpointSm}) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
`

const Card = styled.div`
    background-color: ${paletteWhite};
    display: flex;
    flex-grow: 1;
    box-shadow: 0px 38px 35px -25px ${paletteNeutral200};

    @media (min-width: ${breakpointSm}) {
        width: ${breakpointSm};
        max-height: 800px;
    }

    @media (min-width: ${breakpointMd}) {
        width: ${breakpointMd};
    }
`

const FormSection = styled.div`
    padding: ${spacing5};
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    @media (min-width: ${breakpointSm}) {
        padding: ${spacing10};
    }
`

const MarketingSection = styled.div`
    width: 380px;
    display: none;
    background-image: url("/images/onboarding_background.svg");
    flex-shrink: 0;

    flex-direction: column;
    justify-content: flex-end;

    padding: ${spacing10};
    gap: ${spacing5};
    white-space: pre-wrap;

    @media (min-width: ${breakpointMd}) {
        display: flex;
    }
`

const Emphasis = styled.em`
    color: ${paletteSecondary500};
    font-style: normal;
`

const MarketingHeader = styled.h5`
    color: ${paletteNeutral500};
    ${typographyH4}
    margin: 0;
    font-weight: 400;
`

const MarketingFeature = styled.div`
    color: ${paletteNeutral500};
    display: flex;
    gap: ${spacing2};
    transform: translateX(-${spacing2});
    font-size: 14px;
`

const FeatureIcon = styled(Icon).attrs({ name: "CaretRight" })`
    > * {
        fill: ${paletteSecondary500};
    }
`

const AppLogoIcon = styled(Icon).attrs({ name: "Logo" })`
    width: 130px;
    margin-bottom: ${spacing10};
`
